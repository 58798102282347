import School from '../images/school.jpeg'
import College from '../images/college.jpeg'
import TLC from '../images/tlc.jpeg'
import Swagup from '../images/swagup.jpg'
import Visionify from '../images/visionify.jpeg'
import PeacockSolar from '../images/peacocksolar.jpeg'
import SparkFoundation from '../images/sparkfoundation.jpeg'
import Hackathon from '../images/hackathon01.jpg'
import ShareSnippet from '../images/share-snippet.png'
import Sensecart from '../images/SenseCart.PNG'
import Ekchhat from '../images/ekchhat.png'
import LeeWet from '../images/leewet.png'
import Udemy from '../images/udemy-logo.png'
import HackerRank from '../images/hackerrank-logo.png'

const education = [
  {
    logo: Udemy,
    name: 'Python Bootcamp 2021',
    start: '2018',
    end: '2022',
    heading: 'Udemy',
    doShowOnMobile: true,
    url: 'https://www.udemy.com/certificate/UC-a3de9c90-aa0f-4312-b42a-c2b0902d68d2/',
    desc: [
      ` Python : Master Programming and Development with 15 Projects on 02/10/2021 as taught by Dev Nirwal, Ritu Antil on Udemy. The certificate indicates the entire course was completed as validated by the student. The course duration represents the total video hours of the course at time of most recent completion.`,
    ],
  },
  {
    logo: HackerRank,
    name: 'Python (Basics)',
    start: '2018',
    end: '2022',
    heading: 'HackerRank',
    url: 'https://www.hackerrank.com/certificates/c9145c82d0bc',
    desc: [
      `Python Basic certification from HackerRank, showcasing my foundational skills in Python programming. This certification highlights my ability to solve problems efficiently using core Python concepts.`,
    ],
  },
  {
    logo: Udemy,
    name: 'Git & GitHub',
    start: '2018',
    end: '2022',
    heading: 'Udemy',
    url: 'https://www.udemy.com/certificate/UC-271e63d1-b015-4787-b68b-ed0d23faa716/',
    desc: [
      `Git & GitHub For Beginners - Master Git and GitHub (2023) on 08/10/2020 as taught by Vlad Budnitski on Udemy. The certificate indicates the entire course was completed as validated by the student. The course duration represents the total video hours of the course at time of most recent completion.`,
    ],
  },
  {
    logo: College,
    name: 'MITRC',
    start: '2018',
    end: '2022',
    heading: 'Electrical Engineering',
    doShowOnMobile: true,
    desc: [
      `I earned my degree in Electrical Engineering from Bikaner Technical University. Alongside my studies, I delved deeper into hardware-level programming, mastering microprocessors like Arduino and Raspberry Pi. Furthermore, I initiated my journey into web development, focusing on core Python and Flask frameworks.`,
      `I've also had the honor of being a two-time winner at inter-college project exhibitions, showcasing my innovative projects. Additionally, I proudly represented my college at the prestigious Smart India Hackathon, further demonstrating my passion for problem-solving and innovation.`,
    ],
  },
  {
    logo: School,
    name: 'National Academy',
    start: '2017',
    end: '2018',
    heading: 'Higher Education ( P,C,M )',
    desc: [
      `I completed majors in Maths and Science, and I also delved into databases, mastering MySQL, Java, Oracle, and gaining a grasp of HTML/CSS basics.In addition to my academic achievements, I've also represented my school multiple times at the district level in football and basketball games.`,
      `In addition to my academic achievements, I've also represented my school multiple times at the district level in football and basketball games.`,
    ],
  },
]
const projects = [
  {
    logo: ShareSnippet,
    name: 'Share Snippet',
    start: 'Aug / 2024 ',
    heading: 'Snippet Made Easy',
    doShowOnMobile: true,
    url: 'https://sharesnippet.live',
    desc: [
      `Instant Sharing: Created a real-time snippet-sharing web app that allows users to share code, text, or links with a unique key. Implemented auto-deletion of shared content after 2 minutes, ensuring privacy and security for users.`,
      `Self-Destructing Content: Implemented auto-deletion of shared content after 2 minutes, ensuring privacy and security for users.`,
      `User Engagement Tracking: Developed a feature that notifies the content owner when their snippet is viewed, adding an interactive element to the experience.`,
      `Focused on a user-friendly interface with a modern design, prioritizing simplicity and functionality.`,
    ],
    techStack: ['Render Server', 'WebSocket', 'Netlify', 'ReactJs', 'ExpressJs', 'NodeJs', 'MongoDb', 'Tailwind'],
  },
  {
    logo: Ekchhat,
    name: 'Ekkchat',
    start: 'May / 2020',
    heading: 'Social Covid 19 Project',
    doShowOnMobile: true,
    url: 'https://github.com/itsjatin135s/Ekchhat',
    desc: [
      `It's my initiative in response to covid 19 and ongoing lockdown at that time. I was ambivalenced with the situation. Scarcity of medicine was dealing with life. During the peak of COVID-19, my app reached 2.1k active users,`,
      `During the peak of COVID-19, my app reached 2.1k active users, I feel blessed to make a difference in the lives of a few.`,
      'Developed the full fledged app and deployed in just 2 days working round the clock.',
    ],
    techStack: ['JavaScript', 'Google Analytics', 'MongoDB', 'Tailwind CSS'],
  },
  {
    logo: Sensecart,
    name: 'SenseCart',
    url: 'https://github.com/itsjatin135s/Senscart',
    start: '2018',
    heading: '1st Full-Stack Project',
    doShowOnMobile: true,
    desc: [
      `Created a web application for comparing online products, though it turned out to be a failed attempt due to an inefficient algorithm. I improved its algorithm, drastically reducing query time from around 15 seconds initially to just 3 seconds.`,
      `Later, I improved its algorithm, drastically reducing query time from around 15 seconds initially to just 3 seconds, achieving nearly a fivefold reduction in latency.`,
    ],
    techStack: ['Python', 'Scrapy', 'Django', 'MySQL'],
  },
  {
    logo: LeeWet,
    name: 'LeeWet',
    url: 'https://leewet.netlify.app/',
    heading: 'Check Latest Weather of Your City',
    desc: [
      'LeeWet is a weather forecast progressive web app with a minimalist UI. It provides users with accurate weather information, including current weather conditions. User can check the weather of any location.',
    ],
    techStack: ['ExpressJs', 'OpenWeather API', 'ReactJs'],
    message:
      "LeeWet is a weather forecast progressive web app built using React.js with a minimalist UI. It provides users with accurate weather information, including current conditions, hourly and daily forecasts, and severe weather alerts. LeeWet's clean and straightforward design allows users to quickly access weather data for any location, with intuitive navigation and clear, easy-to-read graphics. As a progressive web app, LeeWet can be installed on a user's device and accessed offline, providing a seamless user experience. With its reliable weather forecasts and intuitive interface, LeeWet has become a go-to resource for people looking to stay informed about the weather.",
  },
]
const experience = [
  {
    logo: Swagup,
    name: 'SwagUp',
    url: 'https://www.swagup.com/',
    start: '2023',
    end: 'Present',
    heading: 'Full Stack Enginner',
    doShowOnMobile: true,
    desc: [
      `Led Back-end and Front-end development at SwagUp, using Python, Django, and ReactJs to build a platform for merchandise
        distribution in cooperation with third-party vendors.`,
      `Collaborated across teams, applying Agile methodologies and Git for efficient project management and code integrity.`,
    ],
    techStack: ['JavaScript', 'React', 'NodeJs', 'Django', 'Python', 'Docker', 'Redis', 'AWS'],
  },
  {
    logo: Visionify,
    name: 'Visionify',
    url: 'https://visionify.ai/',
    start: '2021',
    end: '2023',
    heading: 'Full Stack Enginner',
    doShowOnMobile: true,
    desc: [
      `Worked as a full-stack engineer, where I took on the responsibility of developing scalable systems. Utilizing Python with Django or Flask frameworks for the backend and Vue.js or Node.js for backend services`,
      `I ensured robust and efficient solutions for various projects across the board for our clients.`,
    ],
    techStack: ['NextJs', 'VueJs', 'Redis', 'Flask', 'MySql', 'Azure'],
  },
  {
    logo: SparkFoundation,
    name: 'The Sparks Foundation',
    url: 'https://thesparksfoundationsingapore.org/',
    start: 'Aug / 2021',
    end: 'Sep / 2021',
    heading: 'Web Developer Internship',
    desc: [
      `Worked as a Full Stack Developer on a basic banking system website, and managed to deliver core transactional features of a banking system by maintaining the
        consistency and atomicity of the transaction
        `,
    ],
    techStack: ['HTML', 'CSS', 'JavaScript', 'Flask', 'MongoDb'],
  },
  {
    logo: Hackathon,
    name: 'Smart India Hackathon',
    url: 'https://www.sih.gov.in/',
    start: 'Aug / 2022 ',
    heading: 'Tech Lead',
    doShowOnMobile: true,
    desc: [
      `We led the development of a project aimed at assisting disabled individuals, fostering a deeper understanding of team dynamics and collaboration. Participating in a national-level competition was a thrilling experience`,
      `As a finalist in the 2022 Hackathon, I not only showcased my technical prowess but also learned invaluable lessons in team building. Pushing me to excel and collaborate effectively in a high-stakes environment.`,
    ],
    techStack: ['JavaScript', 'NextJs', 'Firebase', 'SMTP', 'MongoDb'],
  },
  {
    logo: PeacockSolar,
    name: 'Peacock Solar',
    start: 'July / 2021',
    end: 'Sep / 2021',
    heading: 'Web Developer Internship',
    desc: [
      `As an SDE Intern at Peacock Solar, a leading solar energy company, I contributed to projects aimed at providing sustainable energy solutions to households and industries. With a focus on innovation and efficiency, I played a pivotal role in developing and enhancing two major projects within the organization.`,
    ],
    // techStack: ['HTML', 'CSS', 'JavaScript', 'Flask', 'MongoDb'],
  },
  {
    logo: TLC,
    name: 'Thanisha Lifestyle Creation',
    start: 'Feb / 2021',
    end: 'April / 2021',
    heading: 'Web Designer Internship',
    desc: [
      `In February 2021, I embarked on my first internship journey, diving into a full-stack React project. Immersed in the world of antique commerce, I collaborated with a team dedicated to bringing their unique products online. `,
    ],
    techStack: ['React', 'HTML/CSS', 'Figma', 'Photoshop', 'MongoDb'],
  },
]

const AboutData = { education, projects, experience }

export default AboutData
