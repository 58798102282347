import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import FloatingNav from '../components/AboutTabs'
import Card from '../components/AboutCards'
import AboutData from '../data/About'

// Main Component
const AboutMe = () => {
  const [tab, setTab] = React.useState('experience')
  const [selectedId, setSelectedId] = React.useState(null)
  const [selectedTab, setSelectedTab] = React.useState(AboutData.projects)

  React.useEffect(() => {
    setSelectedTab(AboutData[tab])
  }, [tab])

  return (
    <>
      <div className="relative min-h-screen p-6 hidden md:block">
        <FloatingNav setTab={setTab} activeTab={tab} />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-10 mt-0 md:mt-10 ">
          {selectedTab.map(item => (
            <Card key={item.id} item={item} onClick={setSelectedId} />
          ))}
        </div>
      </div>
      <div className="relative min-h-screen block md:hidden mt-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-10 mt-0 md:mt-10">
          <p className="text-left text-2xl font-md mt-5 border-b-2 dark:text-white dark:border-white border-orange-500 pb-2 max-w-max">
            Experience
          </p>

          {AboutData.experience.map(
            item => item.doShowOnMobile && <Card key={item.id} item={item} onClick={setSelectedId} />
          )}
          <p className="text-left text-2xl font-md mt-5 border-b-2 dark:text-white dark:border-white border-orange-500 pb-2 max-w-max">
            Projects
          </p>
          {AboutData.projects.map(
            item => item.doShowOnMobile && <Card key={item.id} item={item} onClick={setSelectedId} />
          )}
          <p className="text-left text-2xl font-md mt-5 border-b-2 dark:text-white dark:border-white border-orange-500 pb-2 max-w-max">
            Education & Certificates
          </p>
          {AboutData.education.map(
            item => item.doShowOnMobile && <Card key={item.id} item={item} onClick={setSelectedId} />
          )}
        </div>
      </div>
    </>
  )
}

export default AboutMe
